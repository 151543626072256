import React from 'react'
import {useSuspenseQuery} from '@apollo/client'
import Loading from '@components/SuspenseLoading/Loading'
import {GetWebsitePublicationsQuery, GetWebsitePublicationsQueryVariables} from '@data/__generated__/types.main'
import {websitePublicationsQuery} from '@data/queries/components/publications.main'
import useWebsiteId from '@hooks/useWebsiteId'

import Publication from './Publication'

import styles from './styles.module.css'

export default function List() {
  const {data: {website}} = useSuspenseQuery<GetWebsitePublicationsQuery, GetWebsitePublicationsQueryVariables>(websitePublicationsQuery, {
    variables: {
      websiteId: useWebsiteId()
    },
    fetchPolicy: 'cache-first'
  })

  const publications = website?.publications 

  if (!publications) {
    return <Loading />
  }

  return (
    <div className={styles.container}>
      <div className="row center-xs">
        {publications.map(publication => (
          <div className="col-xs-12 col-sm-6 col-md-4 col-lg-3" key={publication._id}>
            <Publication publication={publication} />
          </div>
        ))}
      </div>
    </div>
  )
}
