import getClient from '@providers/apollo/getClient'
import gql from 'graphql-tag'

import {
  getWebsitePublications,
  getWebsitePublicationsVariables
} from './__generated__/getWebsitePublications'

export const websitePublicationsQuery = gql`
  query getWebsitePublications($websiteId: ID) {
    website(websiteId: $websiteId) {
      _id
      publications {
        _id
        title
        description
        image {
          _id
          url: resizedURL(width: 1200, height: 1200)
        }
      }
    }
  }
`

export default async function getPublications(websiteId: string) {
  const {data} = await getClient().query<getWebsitePublications, getWebsitePublicationsVariables>({
    query: websitePublicationsQuery,
    variables: {
      websiteId
    }
  })

  return data.website.publications
}
