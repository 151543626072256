import React from 'react'
import useComponentWidth from '@hooks/useComponentWidth'
import useRef from '@hooks/useRef'
import Link from 'next/link'

import styles from './styles.module.css'

export default function Publication(props) {
  const {publication} = props
  const container = useRef()
  const width = useComponentWidth(container)
  return (
    (<Link href={`/p/${publication._id}`} className={styles.container}>

      <div
        ref={container}
        className={styles.image}
        style={{height: width, backgroundImage: `url(${publication.image.url})`}}
      />
      <div className={styles.box}>
        <div className={styles.title}>{publication.title}</div>
        <div className={styles.description}>{publication.description}</div>
      </div>

    </Link>)
  );
}
