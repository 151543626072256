import React from 'react'
import Container from '@packages/justo-parts/lib/components/Container'

import List from './List'

import styles from './styles.module.css'

export default function Publications() {
  return (
    <div className={styles.container}>
      <Container>
        <List />
      </Container>
    </div>
  )
}
